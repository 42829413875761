html, body {
  height: 100%; }

body {
  margin: 0;
  padding-top: 0.1px; }

top-app-bar__align-center {
  justify-content: center;
  justify-items: center; }

/*
 0 padding
*/
@font-face {
  font-family: 'Renens';
  src: url("./assets/fonts/Renens-Medium.woff2") format("woff2"), url("./assets/fonts/Renens-Medium.woff") format("woff"), url("./assets/fonts/Renens-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'KPL Diploma';
  src: url("assets/fonts/KPLDiploma-Regular.woff2") format("woff2"), url("assets/fonts/KPLDiploma-Regular.woff") format("woff"), url("assets/fonts/KPLDiploma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KPL Diploma';
  src: url("assets/fonts/KPLDiploma-Italic.woff2") format("woff2"), url("assets/fonts/KPLDiploma-Italic.woff") format("woff"), url("assets/fonts/KPLDiploma-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: white;
  /* @noflip */
  border-radius: 0 0 0 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* @noflip */
  border-right-width: 1px;
  /* @noflip */
  border-right-style: solid;
  overflow: hidden; }
  .mdc-drawer .mdc-drawer__title {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-drawer .mdc-list-group__subheader {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-drawer .mdc-drawer__subtitle {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-drawer .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-drawer .mdc-list-item {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
    color: white; }
  .mdc-drawer .mdc-list-item--activated {
    color: rgba(255, 255, 255, 0.87); }
  [dir="rtl"] .mdc-drawer, .mdc-drawer[dir="rtl"] {
    /* @noflip */
    border-radius: 0 0 0 0; }
  .mdc-drawer .mdc-list-item {
    border-radius: 4px; }
  .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
    /* @noflip */
    margin-left: 256px;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 256px; }
  [dir="rtl"] .mdc-drawer, .mdc-drawer[dir="rtl"] {
    /* @noflip */
    border-right-width: 0;
    /* @noflip */
    border-left-width: 1px;
    /* @noflip */
    border-right-style: none;
    /* @noflip */
    border-left-style: solid; }
  .mdc-drawer .mdc-list-item {
    font-family: KPL Diploma, Arial;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 500;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit;
    height: calc(48px - 2 * 4px);
    margin: 8px 8px;
    padding: 0 8px; }
  .mdc-drawer .mdc-list-item:nth-child(1) {
    margin-top: 2px; }
  .mdc-drawer .mdc-list-item:nth-last-child(1) {
    margin-bottom: 0; }
  .mdc-drawer .mdc-list-group__subheader {
    font-family: KPL Diploma, Arial;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.01786em;
    text-decoration: inherit;
    text-transform: inherit;
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    margin: 0;
    padding: 0 16px; }
    .mdc-drawer .mdc-list-group__subheader::before {
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
      vertical-align: 0; }
  .mdc-drawer .mdc-list-divider {
    margin: 3px 0 4px 0; }
  .mdc-drawer .mdc-list-item__text,
  .mdc-drawer .mdc-list-item__graphic {
    pointer-events: none; }

.mdc-drawer--animate {
  transform: translateX(-100%); }
  [dir="rtl"] .mdc-drawer--animate, .mdc-drawer--animate[dir="rtl"] {
    transform: translateX(100%); }

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms; }
  [dir="rtl"] .mdc-drawer--opening, .mdc-drawer--opening[dir="rtl"] {
    transform: translateX(0); }

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms; }
  [dir="rtl"] .mdc-drawer--closing, .mdc-drawer--closing[dir="rtl"] {
    transform: translateX(100%); }

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px; }

.mdc-drawer__title {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px; }
  .mdc-drawer__title::before {
    display: inline-block;
    width: 0;
    height: 36px;
    content: "";
    vertical-align: 0; }
  .mdc-drawer__title::after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: -20px; }

.mdc-drawer__subtitle {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0; }
  .mdc-drawer__subtitle::before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: 0; }

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.mdc-drawer--dismissible {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute; }
  [dir="rtl"] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 0; }
  .mdc-drawer--dismissible.mdc-drawer--open {
    display: flex; }

.mdc-drawer-app-content {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
  position: relative; }
  [dir="rtl"] .mdc-drawer-app-content, .mdc-drawer-app-content[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }

.mdc-drawer--modal {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: fixed; }
  .mdc-drawer--modal + .mdc-drawer-scrim {
    background-color: rgba(0, 0, 0, 0.32); }
  [dir="rtl"] .mdc-drawer--modal, .mdc-drawer--modal[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 0; }
  .mdc-drawer--modal.mdc-drawer--open {
    display: flex; }

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5; }
  .mdc-drawer--open + .mdc-drawer-scrim {
    display: block; }
  .mdc-drawer--animate + .mdc-drawer-scrim {
    opacity: 0; }
  .mdc-drawer--opening + .mdc-drawer-scrim {
    transition-duration: 250ms;
    opacity: 1; }
  .mdc-drawer--closing + .mdc-drawer-scrim {
    transition-duration: 200ms;
    opacity: 0; }

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

.mdc-list {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, white);
  margin: 0;
  padding: 8px 0;
  /* @alternate */
  line-height: 1.5rem;
  list-style-type: none; }

.mdc-list-item__secondary-text {
  color: rgba(255, 255, 255, 0.7);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7)); }

.mdc-list-item__graphic {
  background-color: transparent; }

.mdc-list-item__graphic {
  color: rgba(255, 255, 255, 0.5);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5)); }

.mdc-list-item__meta {
  color: rgba(255, 255, 255, 0.5);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5)); }

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: .812rem; }

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 0 16px;
  overflow: hidden; }
  .mdc-list-item:focus {
    outline: none; }

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-primary, white); }
  .mdc-list-item--selected .mdc-list-item__graphic,
  .mdc-list-item--activated .mdc-list-item__graphic {
    color: white;
    /* @alternate */
    color: var(--mdc-theme-primary, white); }

.mdc-list-item--disabled {
  color: rgba(255, 255, 255, 0.5);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5)); }

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 32px;
    /* @noflip */
    margin-right: 0; }

.mdc-list .mdc-list-item__graphic {
  display: inline-flex; }

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__meta,
  [dir="rtl"] .mdc-list-item .mdc-list-item__meta {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: auto; }

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.mdc-list-item__text[for] {
  pointer-events: none; }

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
  display: block; }
  .mdc-list-item__primary-text::before {
    display: inline-block;
    width: 0;
    height: 32px;
    content: "";
    vertical-align: 0; }
  .mdc-list-item__primary-text::after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: -20px; }
  .mdc-list--dense .mdc-list-item__primary-text {
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    margin-bottom: -20px; }
    .mdc-list--dense .mdc-list-item__primary-text::before {
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
      vertical-align: 0; }
    .mdc-list--dense .mdc-list-item__primary-text::after {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: -20px; }

.mdc-list-item__secondary-text {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  display: block; }
  .mdc-list-item__secondary-text::before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: 0; }
  .mdc-list--dense .mdc-list-item__secondary-text {
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    font-size: inherit; }
    .mdc-list--dense .mdc-list-item__secondary-text::before {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: 0; }

.mdc-list--dense .mdc-list-item {
  height: 40px; }

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 36px;
  width: 20px;
  height: 20px; }
  .mdc-list-item[dir="rtl"] .mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 36px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--avatar-list .mdc-list-item {
  height: 56px; }

.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start; }

.mdc-list--two-line .mdc-list-item {
  height: 72px; }

.mdc-list--two-line.mdc-list--dense .mdc-list-item {
  height: 60px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 20px;
  width: 36px;
  height: 36px; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 20px;
    /* @noflip */
    margin-right: 0; }

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    background-color: black; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:hover::before {
    opacity: 0.04; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before {
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
    background-color: white; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, white); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:hover::before {
    opacity: 0.32; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.48; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.56; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.56; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before {
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
    background-color: white; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, white); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover::before {
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.4; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.48; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.48; }

a.mdc-list-item {
  color: inherit;
  text-decoration: none; }

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.mdc-list-divider {
  border-bottom-color: rgba(255, 255, 255, 0.2); }

.mdc-list-divider--padded {
  margin: 0 16px; }

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px); }
  .mdc-list-group[dir="rtl"] .mdc-list-divider--inset,
  [dir="rtl"] .mdc-list-group .mdc-list-divider--inset {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 72px; }

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px); }

.mdc-list-group .mdc-list {
  padding: 0; }

.mdc-list-group__subheader {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0.75rem 16px; }

.mdc-list-group__subheader {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, white); }

.mdc-top-app-bar {
  background-color: white;
  /* @alternate */
  background-color: var(--mdc-theme-primary, white);
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4; }
  .mdc-top-app-bar .mdc-top-app-bar__action-item,
  .mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
    color: black;
    /* @alternate */
    color: var(--mdc-theme-on-primary, black); }
    .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
      background-color: black; }
      @supports not (-ms-ime-align: auto) {
        .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
        .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
        .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
          /* @alternate */
          background-color: var(--mdc-theme-on-primary, black); } }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before {
      opacity: 0.04; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
  .mdc-top-app-bar__row {
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 64px; }
  .mdc-top-app-bar__section {
    display: inline-flex;
    flex: 1 1 auto;
    align-items: center;
    min-width: 0;
    padding: 8px 12px;
    z-index: 1; }
    .mdc-top-app-bar__section--align-start {
      justify-content: flex-start;
      order: -1; }
    .mdc-top-app-bar__section--align-end {
      justify-content: flex-end;
      order: 1; }
  .mdc-top-app-bar__title {
    font-family: KPL Diploma, Arial;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @noflip */
    padding-left: 20px;
    /* @noflip */
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1; }
    [dir="rtl"] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir="rtl"] {
      /* @noflip */
      padding-left: 0;
      /* @noflip */
      padding-right: 20px; }
  .mdc-top-app-bar__action-item, .mdc-top-app-bar__navigation-icon {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    padding: 12px;
    border: none;
    outline: none;
    background-color: transparent;
    fill: currentColor;
    color: inherit;
    text-decoration: none;
    cursor: pointer; }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__navigation-icon::before {
      transition: opacity 15ms linear, background-color 15ms linear;
      z-index: 1; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before {
      transform: scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      top: 0;
      /* @noflip */
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--unbounded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      /* @noflip */
      left: var(--mdc-ripple-left, 0); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-activation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-deactivation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
      top: calc(50% - 50%);
      /* @noflip */
      left: calc(50% - 50%);
      width: 100%;
      height: 100%; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      /* @noflip */
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-radius: 0 0 24px 0; }
  [dir="rtl"] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir="rtl"] {
    /* @noflip */
    border-radius: 0 0 0 24px; }

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */
  right: auto;
  /* @noflip */
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  [dir="rtl"] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir="rtl"] {
    /* @noflip */
    right: 0;
    /* @noflip */
    left: auto; }
  .mdc-top-app-bar--short .mdc-top-app-bar__row {
    height: 56px; }
  .mdc-top-app-bar--short .mdc-top-app-bar__section {
    padding: 4px; }
  .mdc-top-app-bar--short .mdc-top-app-bar__title {
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }

.mdc-top-app-bar--short-collapsed {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
    display: none; }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
    transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px; }
  .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px; }
    [dir="rtl"] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir="rtl"] {
      /* @noflip */
      padding-left: 12px;
      /* @noflip */
      padding-right: 0; }

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px; }

.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px; }

.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0; }
  [dir="rtl"] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start; }

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear; }

.mdc-top-app-bar--fixed-scrolled {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px; }
  [dir="rtl"] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 20px; }

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px; }

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px; }

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px; }

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px; }

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px; }

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px; }
  .mdc-top-app-bar__section {
    padding: 4px; }
  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1); }
    .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
      /* @noflip */
      padding-left: 0;
      /* @noflip */
      padding-right: 12px; }
      [dir="rtl"] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir="rtl"] {
        /* @noflip */
        padding-left: 12px;
        /* @noflip */
        padding-right: 0; }
  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px; }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px; } }

.mdc-typography {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.mdc-typography--headline1 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 300;
  letter-spacing: -0.01562em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline2 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.00833em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline3 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3rem;
  line-height: 3.125rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline4 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.00735em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline5 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline6 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle1 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle2 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body1 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body2 {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--caption {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--button {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-typography--overline {
  font-family: KPL Diploma, Arial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.16667em;
  text-decoration: none;
  text-transform: uppercase; }

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px; }

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px); } }

@media (max-width: 479px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px); } }

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 24px;
        grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
        grid-template-columns: repeat(12, minmax(0, 1fr)); } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
        grid-template-columns: repeat(8, minmax(0, 1fr)); } } }

@media (max-width: 479px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
        grid-template-columns: repeat(4, minmax(0, 1fr)); } } }

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.33333% - 24px);
    width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: calc(8.33333% - 24px);
      width: calc(8.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-desktop {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: calc(16.66667% - 24px);
      width: calc(16.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-desktop {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: calc(25% - 24px);
      width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-desktop {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: calc(33.33333% - 24px);
      width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-desktop {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: calc(41.66667% - 24px);
      width: calc(41.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-desktop {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: calc(50% - 24px);
      width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-desktop {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: calc(58.33333% - 24px);
      width: calc(58.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-desktop {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: calc(66.66667% - 24px);
      width: calc(66.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-desktop {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: calc(75% - 24px);
      width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-desktop {
          width: auto;
          grid-column-end: span 9; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: calc(83.33333% - 24px);
      width: calc(83.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-desktop {
          width: auto;
          grid-column-end: span 10; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: calc(91.66667% - 24px);
      width: calc(91.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-desktop {
          width: auto;
          grid-column-end: span 11; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: calc(100% - 24px);
      width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-desktop {
          width: auto;
          grid-column-end: span 12; } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: calc(12.5% - 16px);
      width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-tablet {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-tablet {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: calc(37.5% - 16px);
      width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-tablet {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-tablet {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: calc(62.5% - 16px);
      width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-tablet {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-tablet {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: calc(87.5% - 16px);
      width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-tablet {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-tablet {
          width: auto;
          grid-column-end: span 8; } } }

@media (max-width: 479px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-phone {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-phone {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-phone {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-phone {
          width: auto;
          grid-column-end: span 4; } } }

.mdc-layout-grid__cell--order-1 {
  order: 1; }

.mdc-layout-grid__cell--order-2 {
  order: 2; }

.mdc-layout-grid__cell--order-3 {
  order: 3; }

.mdc-layout-grid__cell--order-4 {
  order: 4; }

.mdc-layout-grid__cell--order-5 {
  order: 5; }

.mdc-layout-grid__cell--order-6 {
  order: 6; }

.mdc-layout-grid__cell--order-7 {
  order: 7; }

.mdc-layout-grid__cell--order-8 {
  order: 8; }

.mdc-layout-grid__cell--order-9 {
  order: 9; }

.mdc-layout-grid__cell--order-10 {
  order: 10; }

.mdc-layout-grid__cell--order-11 {
  order: 11; }

.mdc-layout-grid__cell--order-12 {
  order: 12; }

.mdc-layout-grid__cell--align-top {
  align-self: flex-start; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-top {
      align-self: start; } }

.mdc-layout-grid__cell--align-middle {
  align-self: center; }

.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-bottom {
      align-self: end; } }

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc( var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc( var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2); } }

@media (max-width: 479px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc( var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2); } }

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0; }

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto; }

html {
  min-height: 100%;
  font-family: KPL Diploma, Helvetica, Arial;
  font-size: 28px; }
  @media (max-width: 479px) {
    html {
      font-size: 14px; } }

header {
  top: 0; }

body {
  display: flex;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  height: 100%; }

.mdc-drawer-app-content {
  flex: auto;
  overflow: auto;
  position: relative; }

.credits_cell {
  border-bottom: 1px solid black; }
  @media (max-width: 479px) {
    .credits_cell:nth-last-child(-n+1) {
      border-bottom: 0px solid black; } }
  @media (min-width: 480px) and (max-width: 839px) {
    .credits_cell:nth-last-child(-n+2) {
      border-bottom: 0px solid black; } }
  @media (min-width: 840px) {
    .credits_cell {
      font-size: 0.71rem; }
      .credits_cell:nth-last-child(-n+3) {
        border-bottom: 0px solid black; } }

/****************/
/* Hacks */
/********/
.mdc-layout-grid__cell--span-4 {
  margin-bottom: -3px; }

.mdc-layout-grid__cell--span-8 {
  margin-bottom: -3px; }

/*******************
*    Top app bar
********************/
.demo-menu {
  position: absolute;
  right: 20px; }

.top-app-bar__align-center {
  justify-content: center;
  padding-left: 0; }

.no-padding {
  padding: 0; }

.inner_image_grid {
  padding: 0 auto 0 0;
  grid-gap: 0; }

.image_grid {
  padding: 0;
  background-color: #f9f9f9; }

.mdc-top-app-bar__title {
  font-size: 1rem; }
  @media (max-width: 479px) {
    .mdc-top-app-bar__title {
      font-size: 1.43rem; } }

#credits {
  grid-row-gap: 0; }

.app-bar {
  position: absolute;
  font-size: 1rem; }
  @media (max-width: 479px) {
    .app-bar {
      font-size: 1.43rem; } }
  .app-bar img {
    height: 1rem; }
    @media (max-width: 479px) {
      .app-bar img {
        height: 1.43rem; } }

.project_title {
  font-style: italic; }

.mdc-top-app-bar {
  z-index: 7; }

img {
  max-width: 100%; }

video {
  max-width: 100%; }

hr {
  border-color: black;
  border-width: 1px 0 0 0; }

#main-page {
  position: inherit;
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  padding: 0 !important; }
  @media (min-width: 840px) {
    #main-page::-webkit-scrollbar {
      background-color: #ffffff;
      width: 13px; }
    #main-page::-webkit-scrollbar-thumb {
      background-color: #f5f5f5;
      border-radius: 6px;
      border: 2px solid #ffffff; }
    #main-page::-webkit-scrollbar-thumb:hover {
      background-color: #c1c1c1; } }

#about-page {
  height: calc(100% - 64px);
  top: -100%;
  display: block;
  position: absolute;
  z-index: 6;
  background-color: white;
  transition-property: top;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
  /* has to be scroll, not auto */
  margin-bottom: 56px;
  margin-top: 64px;
  /* overflow: initial; */ }
  @media (max-width: 479px) {
    #about-page {
      height: calc(100% - 56px);
      margin-top: 56px; } }
  @media (min-width: 840px) {
    #about-page::-webkit-scrollbar {
      background-color: #ffffff;
      width: 13px; }
    #about-page::-webkit-scrollbar-thumb {
      background-color: #f5f5f5;
      border-radius: 6px;
      border: 2px solid #ffffff; }
    #about-page::-webkit-scrollbar-thumb:hover {
      background-color: #c1c1c1; } }

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important; }

:root {
  --icons_height: 0.55rem; }

/*******************
*     Credits
********************/
.project_title:before {
  content: '';
  width: 100%;
  height: 1em;
  display: inline-block; }

.about-credits {
  font-size: 1rem; }
  @media (min-width: 840px) {
    .about-credits {
      font-size: 0.72rem; } }

.project_credits {
  margin-block-end: 1em !important; }

.project_desc_fr {
  letter-spacing: 0; }

.gray {
  color: #818181; }

.project_desc_en {
  color: #818181; }
  .project_desc_en::after {
    content: '';
    width: 100%;
    height: 1em;
    display: inline-block; }

.footer::after {
  content: '';
  width: 100%;
  height: 2.25rem;
  display: inline-block; }

hr.divider__slim {
  border-color: black;
  border-width: 1px 0 0 0; }
  hr.divider__slim:last-child {
    display: none; }

.close_margins {
  margin-block-end: 0;
  margin-block-start: 0; }

.divider__fat {
  border-color: black;
  border-width: 3px 0 0 0;
  margin-top: 2em;
  margin-bottom: 2em; }

/***********
*  Footer
************/
.sonette_container {
  position: relative; }

.sonette_overlay {
  z-index: 3;
  position: absolute;
  top: 0px;
  display: none;
  pointer-events: none; }

.icon_spread {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em; }

.mdc-top-app-bar__section {
  z-index: 10; }

.icon_spread a {
  display: contents; }

.section_logos img {
  height: 0.8rem; }

@media (max-width: 479px) {
  .section_logos {
    order: 3; }
    .section_logos img {
      height: 1.3rem; } }

@media (min-width: 480px) and (max-width: 839px) {
  .section_logos {
    order: 3; }
    .section_logos img {
      height: 1.3rem; } }

.social {
  align-items: center; }

@media (max-width: 479px) {
  .section_social {
    order: 1; }
    .section_social img {
      height: 1.1rem; } }

@media (min-width: 480px) and (max-width: 839px) {
  .section_social {
    order: 1; }
    .section_social img {
      height: 1.1rem; } }
